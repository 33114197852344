import { appStore } from "@/main";
import i18n from "@/lang/i18n.js";

const iconPerson = require("@/modules/OperatorPanel/assets/images/icon-person.jpg");
const iconLogo = require("@/modules/OperatorPanel/assets/images/logo-widget.svg");

const options = {
  // second: "2-digit",
  // minute: "2-digit",
  // hour: "2-digit",
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
};
const INTL = new Intl.DateTimeFormat("ru-RU", options);
const INTLTIME = new Intl.DateTimeFormat("ru-RU", {
  minute: "2-digit",
  hour: "2-digit",
});
const INTLDATETIME = new Intl.DateTimeFormat("ru-RU", {
  minute: "2-digit",
  hour: "2-digit",
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
});

const runFns = function runMultipleFunctions(...args) {
  return function pipeItemThrowFns(item) {
    const run = (fn) => fn(item);
    args.forEach(run);
  };
};

export default {
  getClientInfo(dialog) {
    const locDialog = dialog || {};
    const participants = locDialog.participants || [];

    const isClient = (participant) => participant.type === "client";

    const clientInfo = participants.find(isClient);

    return clientInfo || {};
  },

  formatClientName(client) {
    const locClient = client || {};

    const {
      full_name: fullName,
      first_name: firstName,
      last_name: lastName,
    } = locClient;

    if (fullName) {
      return fullName;
    }

    let clientName = "";

    if (firstName) {
      clientName += firstName;
    }

    if (lastName) {
      clientName = clientName !== "" ? `${clientName} ${lastName}` : lastName;
    }

    return clientName;
  },

  formatDialogName(dialog) {
    const locDialog = dialog || {};
    const clientInfo = this.getClientInfo(locDialog);
    const client = clientInfo.client || {};
    const clientName = this.formatClientName(client);

    if (clientName !== "") return clientName;

    if (dialog?.kw_chat_title) return dialog.kw_chat_title;

    const { kw_channel: channelName, kw_chat_id: chatId } = locDialog;

    return `${channelName}_${chatId}`;
  },

  getDefaultAvatar() {
    return iconPerson;
  },

  getFirstLettersClient (client) {
    if (client?.first_name) {
      return client?.first_name.slice(0, 2);
    } else if (client?.full_name){
      return client?.full_name.slice(0, 2);
    } else {
      return 'No'
    }
  },

  getFirstLettersOperator (user) {
    if (user?.first_name) {
      return user?.first_name.slice(0, 2);
    } else if (user?.last_name){
      return user?.full_name.slice(0, 2);
    } else {
      return 'No'
    }
  },

  getChatOperators(dialog) {
    const isUser = (participant) => participant.type === "user";

    const locDialog = dialog || {};
    const participants = locDialog.participants || [];
    const users = participants.filter(isUser);

    return users;
  },

  async getNotificationPermission() {
    const { permission } = Notification;
    let newPermission;

    switch (permission) {
      case "granted":
      case "denied":
        newPermission = permission;
        break;

      default:
        newPermission = await Notification.requestPermission();
        break;
    }

    return newPermission;
  },

  async showBrowserNotification(settings = {}) {
    const permission = await this.getNotificationPermission();

    if (permission !== "granted") return;

    const { title = i18n.t("modules.op.new_message"), body = i18n.t("modules.op.read") } = settings;

    // const icon = process.env.VUE_APP_OP_NOTIFICATION_ICON;
    const icon = iconLogo;
    const options = { body };

    if (icon) {
      options.icon = icon;
    }

    const notifier = new Notification(title, options);

    notifier.onclick = () => {
      window.focus();
      notifier.close();
    };
  },

  formatUserName(userObj) {
    const { first_name: firstName, last_name: lastName } = userObj;

    let userName = "";

    if (firstName) {
      userName += firstName;
    }

    if (lastName) {
      userName = userName !== "" ? `${userName} ${lastName}` : lastName;
    }

    return userName;
  },

  formatDialogMsgs(options) {
    const { messages, userId } = options;

    const getName = (message) => {
      if (!message) return null;

      const user = message.user_id ? message.user : message.client;
      const name = this.formatClientName(user);

      return name;
    };

    const formatMsg = function formatMsgForChat(message) {
      const messageType = message.system ? "system" : message.message_type;

      const msg = {
        id: message.id,
        external_id: message?.external_id,
        reply_message_id: message?.reply_message_id,
        reply_to_message: message?.reply_to_message,
        type: messageType,
        data: {
          time: message.send_datetime,
          ...JSON.parse(message.message_data),
          avatar: message.participant?.user?.avatar_img,
        },
      };

      const isMe = message?.participant?.user_id === userId;
      const messageAuthor = isMe ? "me" : getName(message.participant);

      // const getUrl = (str) => {
      //   const urlRegex = /(https?:\/\/[^\s]+)/g;

      //   const urlArr = str.match(urlRegex);
      //   const urlText = str.replace(urlRegex, "").trim();

      //   return {
      //     name: urlText,
      //     url: urlArr === null ? "" : urlArr[0],
      //   };
      // };

      const getUrl = (str) => {
        const urlArr = str.split(": ");
        const objMessageData = {...JSON.parse(message.message_data)}

        if (objMessageData?.original_filename) {
          return {
            name: objMessageData.original_filename,
            url: str || "",
          };
        } else {
          return {
            name: urlArr[0],
            url: urlArr[1] || "",
          };
        }
      };

      switch (messageType) {
        case "system":
          msg.data.text = message.message_text;
          break;

        case "file":
          msg.author = messageAuthor;
          msg.data.text = "";
          msg.data.file = getUrl(message.message_text);
          break;

        default:
          msg.author = messageAuthor;
          msg.data.text = message.message_text;
          break;
      }

      return msg;
    };

    return messages.map(formatMsg);
  },

  formatDialogParticipants(chatRoom) {
    const participants = chatRoom?.participants || [];

    const formatParticipant = (participant) => {
      const { id, client } = participant;

      const clientName = client ? this.formatDialogName(chatRoom) : "me";

      return {
        id,
        imageUrl: iconPerson,
        name: clientName,
      };
    };

    return participants.map(formatParticipant);
  },

  toFormData(obj) {
    const formData = new FormData();

    const toForm = ([key, val]) => {
      const isObj = typeof val === "object";
      const isNotNull = val !== null;
      const isNotFile = !(val instanceof File);

      return isObj && isNotNull && isNotFile
        ? formData.append(key, JSON.stringify(val))
        : formData.append(key, val);
    };

    Object.entries(obj).forEach(toForm);

    return formData;
  },

  handleError([error, title, description]) {
    let errorMsg = error?.response?.data?.message_alias;
    let locTitle, locDescription;

    if (!navigator.onLine && !errorMsg) errorMsg = 'connect_error'

    switch (errorMsg) {
      case "access_error":
        locTitle = i18n.t("modules.op.access_denied");
        locDescription = i18n.t("modules.op.lack_rights");
        break;

      case "connect_error":
        locTitle = i18n.t("common.error");
        locDescription = i18n.t("common.error_network");
        break;

      case "no_original_name":
        locTitle = i18n.t("common.error");
        locDescription = i18n.t("modules.op.errors.no_original_name");
        break;
      case "invalid_mime_type":
        locTitle = i18n.t("common.error");
        locDescription = i18n.t("modules.op.errors.invalid_mime_type");
        break;
      case "invalid_file_size":
        locTitle = i18n.t("common.error");
        locDescription = i18n.t("modules.op.errors.invalid_file_size");
        break;

      default:
        locTitle = title;
        locDescription = description;
        break;
    }

    appStore.dispatch("updateAjaxDialogError", [
      error,
      locTitle,
      locDescription,
    ]);
  },

  rebuildChatRooms(options) {
    const { history = [], subjects = [], users = [] } = options;

    const getTheme = function getSubjectNameById(subjectId) {
      const isEqualId = (subject) => subject.id === subjectId;
      const subject = subjects.find(isEqualId);
      return subject?.name || i18n.t('modules.op.texts.without_subject');
    };

    const addSubjectName = function addSubjectNameToHistory(historyItem) {
      const subjectId = historyItem.subject_id;
      const theme = getTheme(subjectId);

      const date = new Date(historyItem.createdAt);
      const formatDate = INTL.format(date);

      historyItem.theme = theme;
      historyItem.date = formatDate;
      historyItem.name = `${theme} ${formatDate}`;
    };

    const addUserObj = function addUserObjectToHistory(participant) {
      const userId = participant.user_id;
      const isEqualUserId = (user) => user.id === userId;
      const userObj = users.find(isEqualUserId);

      participant.user = userObj || null;
    };

    const addUsers = function addUserObjectToHistoryLoop(historyItem) {
      historyItem.participants.forEach(addUserObj);
    };

    const buildHistory = runFns(addSubjectName, addUsers);

    history.forEach(buildHistory);

    return history;
  },

  getChatRoomIdFromUrl() {
    const urlSearch = new URLSearchParams(window.location.search);

    if (!urlSearch.has("chat_room_id")) return null;

    const chatRoomId = Number(urlSearch.get("chat_room_id"));

    return isNaN(chatRoomId) ? null : chatRoomId;
  },

  formatDateTime(dateStr, fullData = false) {
    const locDateStr = dateStr.split('-').join('/');
    const date = new Date(locDateStr);

    date.setHours(date.getHours() - (new Date().getTimezoneOffset() / 60));

    const formatDate = (fullData) ? INTLDATETIME.format(date) : INTLTIME.format(date);

    return formatDate;
  },

  formatTimeStampToDateTime (timeStamp) {
    const date = new Date(timeStamp)
    const hours = date.getHours();
    const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();

    return hours + ':' + minutes + ', ' + day + '.' + month + '.' + year
  },

  /**
   * @name scrollToInsideParent
   * @description Scroll To element inside Parent
   * @param parentElement
   * @param to
   * @param duration
   */
  scrollToInsideParent(parentElement, to, duration) {
    let start = parentElement.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    function math_easeInOutQuad (t, b, c, d) {
      t /= d/2;
      if (t < 1) return c/2*t*t + b;
      t--;
      return -c/2 * (t*(t-2) - 1) + b;
    }

    function animateScroll (){
      currentTime += increment;
      var val = math_easeInOutQuad(currentTime, start, change, duration);
      parentElement.scrollTop = val;
      if(currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    }

    //init scroll
    animateScroll();
  },
	/**
	 * @description replace system message for languages
	 * @param text
	 * @returns {*}
	 */
	replaceSystemMessagesLanguages(text) {
		const currentLocale = i18n.locale
		const systemMessagesObj = i18n.messages?.[currentLocale]?.modules?.op?.texts?.system_messages

		if(Object.keys(systemMessagesObj).length) {
			for(let message in systemMessagesObj) {
				text = text.replace(message, systemMessagesObj[message])
			}
		}

		return text
	},

	returnChannelIcon(channelName) {
		return {
			'telegram': require('@/modules/OperatorPanel/assets/images/logo-telegram.svg'),
			'viber': require('@/modules/OperatorPanel/assets/images/logo-viber.svg'),
			'messenger': require('@/modules/OperatorPanel/assets/images/logo-messenger.svg'),
			'facebook': require('@/modules/OperatorPanel/assets/images/logo-messenger.svg'),
			'kwizbot': require('@/modules/OperatorPanel/assets/images/logo-kwizbot.svg'),
			'instagram': require('@/modules/OperatorPanel/assets/images/logo-instagram.svg'),
			'whatsapp': require('@/modules/OperatorPanel/assets/images/logo-whatsapp.svg'),
			'sms': require('@/modules/OperatorPanel/assets/images/logo-sms.svg'),
			'widget': require('@/modules/OperatorPanel/assets/images/logo-widget.svg'),
			'custom_channel': require('@/modules/OperatorPanel/assets/images/logo-kwizbot.svg'),
		} [channelName] || require('@/modules/OperatorPanel/assets/images/logo-kwizbot.svg')
	},
};
