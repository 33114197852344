var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.itemName === 'id')?[_vm._v(" "+_vm._s(_vm.value)+" ")]:(_vm.is_association)?[_c('v-select',{staticClass:"colored_select",attrs:{"dense":"","solo":"","flat":"","hide-details":"","attach":"","background-color":_vm.getColorSelect || 'transparent',"append-icon":"","item-text":"name","item-value":"id","items":_vm.custom_data_linked_tables_values[_vm.itemName],"menu-props":{minWidth:'200'}},on:{"change":function($event){return _vm.triggerChange($event)}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('span',{style:({'background-color': data.item.color})},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}}]),model:{value:(_vm.item_value),callback:function ($$v) {_vm.item_value=$$v},expression:"item_value"}})]:[_vm._v(" "+_vm._s(/*STRING, TEXT, FLOAT FIELDS*/)+" "),_c('div',{staticStyle:{"position":"relative"}},[(['STRING', 'TEXT', 'FLOAT', 'INTEGER'].includes(_vm.itemType))?_c('v-tooltip',{attrs:{"attach":"","bottom":"","disabled":!_vm.value},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({class:{'non-editable': _vm.itemEditable, 'is-link': _vm.itemDisplayType === 'link', 'is-open': _vm.itemClickAction === 'open'},attrs:{"append-icon":_vm.fieldIcon(),"value":_vm.value,"solo":"","readonly":_vm.readonlyMode || _vm.itemEditable,"hide-details":"","background-color":"transparent","flat":"","single-line":"","dense":""},on:{"click:append":function($event){return _vm.triggerClick()},"click":function($event){return _vm.triggerClick()},"change":function($event){return _vm.triggerChange($event)}}},'v-text-field',attrs,false),on))]}}],null,false,861542788)},[_c('span',[_vm._v(_vm._s(_vm.value))])]):_vm._e()],1),_vm._v(" "+_vm._s(/*BOOL FIELDS*/)+" "),(['BOOLEAN'].includes(_vm.itemType))?_c('v-checkbox',{staticClass:"mb-4",attrs:{"hide-details":""},model:{value:(_vm.item_value_checkbox),callback:function ($$v) {_vm.item_value_checkbox=$$v},expression:"item_value_checkbox"}}):_vm._e(),_vm._v(" "+_vm._s(/*DATE FIELDS*/)+" "),(['DATE'].includes(_vm.itemType))?[(_vm.itemName === 'createdAt')?_c('v-tooltip',{attrs:{"left":"","attach":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"outlined":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.formatDate(_vm.value, true))+" ")])]}}],null,false,3521513431)},[_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.value)))])]):_c('div',{staticClass:"calendar-cell",staticStyle:{"position":"relative"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"transition":"scale-transition","attach":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","solo":"","flat":"","readonly":"","clearable":"","hide-details":"","background-color":"transparent","value":_vm.formatDate(_vm.item_value_date, false, true)},on:{"input":function($event){return _vm.triggerChange($event)}}},'v-text-field',attrs,false),on))]}}],null,false,2429649351)},[_c('v-date-picker',{attrs:{"no-title":"","locale":_vm.i18n.locale,"scrollable":""},model:{value:(_vm.item_value_date),callback:function ($$v) {_vm.item_value_date=$$v},expression:"item_value_date"}})],1)],1)]:_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }