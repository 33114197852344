<template>
  <v-progress-circular
    v-if="getter_ajax_dialog.show && getter_ajax_dialog.loading"
    indeterminate
    color="primary"
    class="preloader-progress"
  ></v-progress-circular>
</template>

<script>
import { mapGetters } from 'vuex';

export default{
    name: "AxiosPreloaderAndResponse",
    props: [],

  data: () => ({
    toastOptions: {
      position: 'top-right',
      timeout: 4000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      closeButton: 'button',
      icon: true,
    },
  }),

    computed: {
        ...mapGetters({
            getter_ajax_dialog: 'ajax_dialog',
        }),

    },

  watch: {
    getter_ajax_dialog: {
      handler(val) {
        if(val?.show && !val?.loading) {

          //Preparing Content
          let myJSX;
          if(val?.title) {
            myJSX = (
              <div>
                <h4><b>{val?.title}</b></h4>
                <div>{val?.description}</div>
              </div>
            );
          } else {
            myJSX = ( <div>{val?.description}</div> );
          }
          //end Preparing Content

          if(val?.error && val?.description) {
            this.$toast.error(myJSX, { ...this.toastOptions });
          } else {
            this.$toast.info(val.description, { ...this.toastOptions });
          }
        }
      },
      deep: true,

    },
  },
}
</script>

<style scoped lang="scss">
  .preloader-progress {
    position: absolute;
    bottom: 50px;
    left: 50%;
    right: 0;
    z-index: 10000;
    transform: translate(-50%, 0);

  }
    .text-center{
        text-align: center !important;
    }
</style>