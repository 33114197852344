import Vue from "vue";
import axios from "axios";
import i18n from "@/lang/i18n.js";
import _ from "lodash";

const state = {
  custom_data:{
    tables:[],

    filters: [],
    filters_schema_json: {
      INTEGER: {
        type: 'INTEGER',
        conditions: [
          {text: i18n.t("modules.customdata.filters.filters_json.eq"), value: 'eq'},//Equal//равно
          {text: i18n.t("modules.customdata.filters.filters_json.ne"), value: 'ne'},//Not equal//не равно

          {text: i18n.t("modules.customdata.filters.filters_json.gt"), value: 'gt'},//Greater than//больше
          {text: i18n.t("modules.customdata.filters.filters_json.lt"), value: 'lt'},//Less than//меньше
          {text: i18n.t("modules.customdata.filters.filters_json.gte"), value: 'gte'},//Greater than or equal//больше или равно
          {text: i18n.t("modules.customdata.filters.filters_json.lte"), value: 'lte'},//Less than or equal//меньше или равно

          {text: i18n.t("modules.customdata.filters.filters_json.empty"), value: 'empty'},//EMPTY//пустое значение
          // {text:'like', value: 'like'},//Like search (%value, value%, %value%)//содержит
          // {text:'notLike', value: 'notLike'},//Not like search (%value, value%, %value%)//не содержит

          {text: i18n.t("modules.customdata.filters.filters_json.between"), value: 'between'},//Between [value1, value2]//в диапазоне
          {text: i18n.t("modules.customdata.filters.filters_json.not_between"), value: 'notBetween'}//Not Between [value1, value2]//не в диапазоне
        ]
      },
      FLOAT: {
        type: 'FLOAT',
        conditions: [
          // {text:'=', value: 'eq'},//Equal
          // {text:'!=', value: 'ne'},//Not equal

          {text: i18n.t("modules.customdata.filters.filters_json.gt"), value: 'gt'},//Greater than
          {text: i18n.t("modules.customdata.filters.filters_json.lt"), value: 'lt'},//Less than
          {text: i18n.t("modules.customdata.filters.filters_json.gte"), value: 'gte'},//Greater than or equal
          {text: i18n.t("modules.customdata.filters.filters_json.lte"), value: 'lte'},//Less than or equal

          {text: i18n.t("modules.customdata.filters.filters_json.empty"), value: 'empty'},//EMPTY
          // {text:'like', value: 'like'},//Like search (%value, value%, %value%)
          // {text:'notLike', value: 'notLike'},//Not like search (%value, value%, %value%)

          {text: i18n.t("modules.customdata.filters.filters_json.between"), value: 'between'},//Between [value1, value2]
          {text: i18n.t("modules.customdata.filters.filters_json.not_between"), value: 'notBetween'}//Not Between [value1, value2]
        ]
      },

      STRING: {
        type: 'STRING',
        conditions: [
          {text: i18n.t("modules.customdata.filters.filters_json.eq"), value: 'eq'},//Equal
          {text: i18n.t("modules.customdata.filters.filters_json.ne"), value: 'ne'},//Not equal


          {text: i18n.t("modules.customdata.filters.filters_json.empty"), value: 'empty'},//EMPTY
          {text: i18n.t("modules.customdata.filters.filters_json.starts_with"), value: 'startsWith'},//Like search (%value,)//начинается с
          {text: i18n.t("modules.customdata.filters.filters_json.ends_with"), value: 'endsWith'},//Like search (value%,)//заканчивается на

          {text: i18n.t("modules.customdata.filters.filters_json.like"), value: 'like'},//Like search (%value%)//содержит
          {text: i18n.t("modules.customdata.filters.filters_json.not_like"), value: 'notLike'},//Not like search (%value, value%, %value%)//не содержит

          {text: i18n.t("modules.customdata.filters.filters_json.multiple"), value: 'multiple'},//multiple select from values//из списка

        ]
      },

      TEXT: {
        type: 'TEXT',
        conditions: [
          {text: i18n.t("modules.customdata.filters.filters_json.eq"), value: 'eq'},//Equal
          {text: i18n.t("modules.customdata.filters.filters_json.ne"), value: 'ne'},//Not equal


          {text: i18n.t("modules.customdata.filters.filters_json.empty"), value: 'empty'},//EMPTY
          {text: i18n.t("modules.customdata.filters.filters_json.starts_with"), value: 'startsWith'},//Like search (%value,)//начинается с
          {text: i18n.t("modules.customdata.filters.filters_json.ends_with"), value: 'endsWith'},//Like search (value%,)//заканчивается на

          {text: i18n.t("modules.customdata.filters.filters_json.like"), value: 'like'},//Like search (%value%)//содержит
          {text: i18n.t("modules.customdata.filters.filters_json.not_like"), value: 'notLike'},//Not like search (%value, value%, %value%)//не содержит

          {text: i18n.t("modules.customdata.filters.filters_json.multiple"), value: 'multiple'},//multiple select from values//из списка

        ]
      },

      BOOLEAN: {
        type: 'BOOLEAN',
        conditions: [
          {text: i18n.t("modules.customdata.filters.filters_json.active"), value: 'active'},//Equal//да
          {text: i18n.t("modules.customdata.filters.filters_json.not_active"), value: 'notActive'},//Not equal//нет

          {text: i18n.t("modules.customdata.filters.filters_json.empty"), value: 'empty'},//EMPTY//пусто
        ]
      },
    },

    selected_filters: {},
    saved_filters: [],
    empty_selected_filters: {},
    linkedTablesValues: {},

    toggle_active_filter: null,

    columns_schema_json: {
      name: '',
      active: true,
      position: 0,
      sort: null,
    },

    columns_data: [],
    columns_data_activated: [],
    saved_columns_data: [],
    default_columns_data: [],

  }
};

const mutations = {
    CUSTOM_DATA_SET_TABLES(state, tables) {
    state.custom_data.tables = tables;
  },
  CUSTOM_DATA_SET_FILTERS(state, filters) {
    state.custom_data.filters = filters;
  },
  CUSTOM_DATA_SET_FILTERS_EMPTY(state) {
    state.custom_data.filters = []
  },
  CUSTOM_DATA_SET_LINKED_TABLE_VALUES(state, {column, values}) {
      Vue.set(state.custom_data.linkedTablesValues, column, values)
  },

  CUSTOM_DATA_CLEAR_LINKED_TABLE_VALUES(state) {
      state.custom_data.linkedTablesValues = {};
  },

  CUSTOM_DATA_SET_EMPTY_SELECTED_FILTER(state, {key, values}){
    Vue.set(state.custom_data.empty_selected_filters, key, values)
    Vue.set(state.custom_data.selected_filters, key, values)
  },

  CUSTOM_DATA_SET_SELECTED_FILTER(state, {key, values}){
    Vue.set(state.custom_data.selected_filters, key, values)
  },

  CUSTOM_DATA_SET_SELECTED_FILTER_VALUE(state, {key, value}){
    Vue.set(state.custom_data.selected_filters[key], 'value', value)
  },
  CUSTOM_DATA_SET_SELECTED_FILTER_CONDITION(state, {key, condition}){
    Vue.set(state.custom_data.selected_filters[key], 'condition', condition)
  },

  CUSTOM_DATA_DELETE_SELECTED_FILTER(state, key){
    Vue.delete(state.custom_data.selected_filters, key)
  },

  CUSTOM_DATA_DELETE_SELECTED_FILTER_IN_ARRAY(state, {key, value}) {
    let keyValueArray = state.custom_data.selected_filters[key]?.value

    if (Array.isArray(keyValueArray) && keyValueArray.length) {
      if (
        state.custom_data.selected_filters[key]?.condition?.value === 'between'
        || state.custom_data.selected_filters[key]?.condition?.value === 'notBetween'
        || state.custom_data.selected_filters[key]?.type === 'DATE'
      ) {
        Vue.set(state.custom_data.selected_filters[key], 'condition', null)
        Vue.set(state.custom_data.selected_filters[key], 'value', null)
      } else {
        let filteredArray = keyValueArray.filter(i => i !== value)
        if (!filteredArray.length) filteredArray = null
        Vue.set(state.custom_data.selected_filters[key], 'value', filteredArray)
        if (keyValueArray.length <= 1) {
          Vue.set(state.custom_data.selected_filters[key], 'condition', null)
        }
      }
    } else {
      Vue.set(state.custom_data.selected_filters[key], 'condition', null)
      Vue.set(state.custom_data.selected_filters[key], 'value', null)
    }
  },
  CUSTOM_DATA_SELECTED_FILTERS_EMPTY(state) {
    state.custom_data.selected_filters = {}
  },

  CUSTOM_DATA_SELECTED_FILTERS_CLEAR_VALUES(state) {
    Object.keys(state.custom_data.selected_filters)
      .map(function(key) {
        Vue.set(state.custom_data.selected_filters[key], 'condition', null)
        Vue.set(state.custom_data.selected_filters[key], 'value', null)
      })
  },

  CUSTOM_DATA_SET_SAVED_FILTERS(state, filters) {
    state.custom_data.saved_filters = filters;
  },
  CUSTOM_DATA_PUSH_SAVED_FILTERS(state, filter) {
    let filterExist = state.custom_data.saved_filters.find(item => item.id === filter.id)
    if (!filterExist) {
      state.custom_data.saved_filters.push(filter);
    } else {
      for (let i in state.custom_data.saved_filters) {
        if (state.custom_data.saved_filters[i].id === filter.id) {
          Vue.set(state.custom_data.saved_filters[i], 'filters_json', filter.filters_json)
        }
      }
    }
  },
  CUSTOM_DATA_DELETE_SAVED_FILTERS(state, name) {
    state.custom_data.saved_filters = state.custom_data.saved_filters.filter(item => item.name !== name);
  },

  CUSTOM_DATA_SET_COLUMNS_DATA(state, payload) {
    state.custom_data.columns_data = payload
  },
  CUSTOM_DATA_SET_COLUMNS_DATA_ACTIVATED(state, payload) {
    state.custom_data.columns_data_activated = payload
  },
  CUSTOM_DATA_TOGGLE_COLUMN_ACTIVE(state, {name, value}) {
    for (let i in state.custom_data.columns_data) {
      if (state.custom_data.columns_data[i].name === name) {
        Vue.set(state.custom_data.columns_data[i], 'active', value)
      }
    }
    state.custom_data.columns_data_activated = state.custom_data.columns_data
  },

  CUSTOM_DATA_CHANGE_ORDER_COLUMN(state, data) {
    const {oldIndex, newIndex} = data
    state.custom_data.columns_data
      .splice(newIndex, 0, state.custom_data.columns_data.splice(oldIndex, 1)[0])

    for (let i in state.custom_data.columns_data) {
      Vue.set(state.custom_data.columns_data[i], 'position', Number(i))
    }

    state.custom_data.columns_data_activated = state.custom_data.columns_data
  },

  CUSTOM_DATA_SET_SAVED_COLUMNS_DATA(state, payload) {
    Vue.set(state.custom_data, 'saved_columns_data', _.cloneDeep(payload))
    // state.custom_data.saved_columns_data = payload
  },
  CUSTOM_DATA_SET_DEFAULT_COLUMNS_DATA(state, payload) {
    state.custom_data.default_columns_data = payload
  },

  CUSTOM_DATA_SET_TOGGLE_ACTIVE_FILTER(state, value) {
    state.custom_data.toggle_active_filter = value
  },
};

const actions = {

    /**
     * Get custom data tables
     * @method CustomDataAxiosGetTables
     */
   async CustomDataAxiosGetTables({ commit, dispatch, state }, botId) {
      let success = false;
      const token = Vue.$cookies.get('token_e')
      const engine_url = state.engine_settings.active_url;
      const bot_id = botId || state.bot_settings.active_id;
      const url = `${engine_url}/kw/custom_modules/list`
      const params = { bot_id }

      //dispatch("updateAjaxDialog", [true, true]);
      await axios.get(url, {
        headers: {
          "X-CSRF-TOKEN": token
        },
        params
      })
          .then( async (response) => {
                //dispatch("updateAjaxDialog", [false, false]);

                if ( response?.data?.status === 'success' && response.data?.data ) {
                  commit('CUSTOM_DATA_SET_TABLES',response.data.data)
                  success = true;
                }
              },
              (err) => {

              dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_get") ]);
              }
          );

      return success;
    },

    /**
     * Create custom data model
     * @method CustomDataAxiosCreateModel
     * @param name - model name
     * @param description  - model description
     * @param columns_json - model columns
     */
     async CustomDataAxiosCreateModel({ dispatch, state }, {name, description, columns_json, statistics_json}) {

      let success = false;

      const token = Vue.$cookies.get('token_e')
      const engine_url = state.engine_settings.active_url;
      const bot_id = state.bot_settings.active_id;
      const user_id = state.user.id;

      const url = `${engine_url}/kw/custom_modules/create_update_module`
      // const mapping_object = {}

      let request = {
        bot_id,
        user_id,
        name,
        description,
        columns_json,
        statistics_json
      }
      dispatch('updateAjaxDialog', [true, true]);

      await axios.post(url, request, {
        headers: {
          "X-CSRF-TOKEN": token
        },
      })
          .then( async (response) => {
               //dispatch('updateAjaxDialog', [false, false]);
                if ( response?.data?.status === 'success' ) {
                  success = true;
                 console.log('CustomDataAxiosCreateModel success!')
                 dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.model.created') ]);
                }
              },
              (err) => {
               dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_save") ]);
              }
          );

      return success;
    },

    /**
     * Delete custom data model with all data
     * @method CustomDataAxiosDeleteModel
     * @param name - model name
     */
    async CustomDataAxiosDeleteModel({ dispatch, state }, {name}) {

      let success = false;

      const token = Vue.$cookies.get('token_e')
      const engine_url = state.engine_settings.active_url;
      const bot_id = state.bot_settings.active_id;
      const user_id = state.user.id;

      const url = `${engine_url}/kw/custom_modules/delete_module/`


      let request = {
        bot_id,
        user_id,
        name
      }

      console.log(request)
      dispatch('updateAjaxDialog', [true, true]);

      await axios.delete(url, {
        headers: {
          "X-CSRF-TOKEN": token
        },
          data:request
      })
          .then( async (response) => {
               //dispatch('updateAjaxDialog', [false, false]);
                if ( response?.data?.status === 'success' ) {
                 success = true;

                 dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.model.deleted') ]);
                }
              },
              (err) => {
               dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_save") ]);
              }
          );

      return success;
    },

    /**
     * Clear custom data model  data
     * @method CustomDataAxiosClearModel
     * @param name - model name
     */
    async CustomDataAxiosClearModel({ dispatch, state }, {name}) {

      let success = false;

      const token = Vue.$cookies.get('token_e')
      const engine_url = state.engine_settings.active_url;
      const bot_id = state.bot_settings.active_id;

      const url = `${engine_url}/kw/custom_modules/${name}/clear_all/?bot_id=${bot_id}`

      dispatch('updateAjaxDialog', [true, true]);

      await axios.delete(url, {
        headers: {
          "X-CSRF-TOKEN": token
        },

      })
          .then( async (response) => {
               //dispatch('updateAjaxDialog', [false, false]);
                if ( response?.data?.status === 'success' ) {
                 success = true;

                 dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.model.cleared') ]);
                }
              },
              (err) => { 
               dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_save") ]);
              }
          );

      return success;
    },

  /**
   * @name customDataAxiosGetFilters
   * @description get list for Filters
   * @param commit
   * @param dispatch
   * @param state
   * @param multiple_entity
   * @returns {Promise<void>}
   */
  async customDataAxiosGetFilters({ commit, dispatch, state }, data) {
		const {multiple_entity, botId} = data
    commit('CUSTOM_DATA_SET_FILTERS_EMPTY')
    commit('CUSTOM_DATA_SELECTED_FILTERS_EMPTY')

    const token = Vue.$cookies.get('token_e')
    const engine_url = state.engine_settings.active_url;
    const bot_id = botId || state.bot_settings.active_id;
    const url = `${engine_url}/kw/custom_modules/get/${multiple_entity}`
    const params = { bot_id }

    dispatch('updateAjaxDialog', [true, true]);

    await axios({
      method: 'GET',
      url: url,
      headers: {
        'X-CSRF-TOKEN': token,
      },
      params,
    })
      .then( (response) => {
          dispatch('updateAjaxDialog', [false, false]);

          if ( response?.data?.status === 'success' ) {
            const filters = response?.data?.data?.columns_json
            const filtersValues = response?.data?.filters

            //set saved filters in state
            const savedFilters = response?.data?.users_filters
            commit('CUSTOM_DATA_SET_SAVED_FILTERS', savedFilters)

            //set columns from columns_chooser
            const columns_chooser = response?.data?.columns_chooser
            if (columns_chooser?.length) {
              dispatch('_customDataSetSavedColumnsData', columns_chooser)
            } else {
              dispatch('_customDataSetSavedColumnsData', [])
            }

            let arr = []

            for (const f in filters) {
              let obj = filters[f]
              obj['name'] = f
              obj['is_association'] = !!filters[f]?.association
              obj['values'] = filtersValues?.[f]
              obj['conditions'] = state.custom_data.filters_schema_json[filters[f].type]?.conditions

              dispatch('customDataSetSelectedFilter', {
                key: f,
                values: {
                  value: null,
                  condition: null,
                  type: filters[f].type,
                  is_association: !!filters[f]?.association,
                  label: filters[f]?.label || null,
                }
              })

              arr.push(obj)

              //for Linked Tables by model_name
              if (filters[f].association) {
                const modelName = filters[f].association.model_name
                const modelField = filters[f].association?.model_field
                dispatch('_customDataAxiosGetLinkedTablesValues', {
                  modelName: modelName,
                  modelField: modelField,
                  linkedColumn: f,
									bot_id: bot_id,
                })
              }
            }

            commit('CUSTOM_DATA_SET_FILTERS', arr)
          }
        },
        (err) => {
          dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_get") ]);
        }
      );
  },

  /**
   * @name customDataAxiosSaveFilters
   * @description save selected filters
   * @param state
   * @param commit
   * @param dispatch
   * @param nameModule
   * @param nameFilter
   * @param filters_json
   * @param with_columns
   * @returns {Promise<void>}
   */
  async customDataAxiosSaveFilters({ state, commit, dispatch }, { nameModule, nameFilter, filters_json, with_columns }) {
    const token = Vue.$cookies.get('token_e')
    const engine_url = state.engine_settings.active_url;
    const user_id = state.user.id;
    const custom_module_id = state.custom_data.tables.find(item => item.name === nameModule).id
    const url = `${engine_url}/kw/custom_modules/create_update_filter`

    let request = {
      custom_module_id,
      user_id,
      name: nameFilter,
      filters_json,
    }

    if (with_columns) {
      request['columns_chooser'] = state.custom_data.columns_data
    }

    dispatch('updateAjaxDialog', [true, true]);
    await axios.post(url, request, {
      headers: {
        "X-CSRF-TOKEN": token
      },
    })
      .then( async (response) => {
          //dispatch('updateAjaxDialog', [false, false]);
          if ( response?.data?.status === 'success' ) {
            commit('CUSTOM_DATA_PUSH_SAVED_FILTERS', response.data.data)
            dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.filters.filter_created') ]);
          }
        },
        (err) => {
          dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_save") ]);
        }
      );
  },

  /**
   * @name customDataAxiosDeleteFilter
   * @description delete filter by name filter and id module
   * @param state
   * @param commit
   * @param dispatch
   * @param nameModule
   * @param nameFilter
   * @returns {Promise<void>}
   */
  async customDataAxiosDeleteFilter({ state, commit, dispatch }, { nameModule, nameFilter }) {
    const token = Vue.$cookies.get('token_e')
    const engine_url = state.engine_settings.active_url;
    const user_id = state.user.id;
    const custom_module_id = state.custom_data.tables.find(item => item.name === nameModule).id

    const url = `${engine_url}/kw/custom_modules/delete_filter`

    let request = {
      custom_module_id,
      name: nameFilter,
      user_id,
    }
    dispatch('updateAjaxDialog', [true, true]);
    await axios.delete(url, {
      headers: {
        "X-CSRF-TOKEN": token
      },
      data:request,
    })
      .then( async (response) => {
          if ( response?.data?.status === 'success' ) {
            commit('CUSTOM_DATA_DELETE_SAVED_FILTERS', nameFilter)
            dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.filters.filter_deleted') ]);
          }
        },
        (err) => {
          dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_save") ]);
        }
      );
  },

  /**
   * @name customDataActivateSavedFilter
   * @description set active filters by click on saved filters
   * @param state
   * @param commit
   * @param dispatch
   * @param data
   */
  customDataActivateSavedFilter({ state, commit, dispatch } , data) {
    //clear data values and condition in Filters List (set null)
    for (let f in state.custom_data.selected_filters) {
      commit('CUSTOM_DATA_SET_SELECTED_FILTER_VALUE', {key: f, value: null})
      commit('CUSTOM_DATA_SET_SELECTED_FILTER_CONDITION', {key: f, condition: null})
    }

    if (data?.filters_json) {
      for(let item of data.filters_json) {
        let name = item[0]
        let value = item[1].value
        let condition = item[1].condition

        commit('CUSTOM_DATA_SET_SELECTED_FILTER_VALUE', {key: name, value: value})
        commit('CUSTOM_DATA_SET_SELECTED_FILTER_CONDITION', {key: name, condition: condition})
      }
    }

    //set columns from saved filter or set saved from model
    if (data?.columns_chooser) {
      commit('CUSTOM_DATA_SET_COLUMNS_DATA', data.columns_chooser)
    } else {
      let columns;
      (state.custom_data.saved_columns_data?.length)
        ? columns = state.custom_data.saved_columns_data
        : columns = state.custom_data.default_columns_data

      commit('CUSTOM_DATA_SET_COLUMNS_DATA', _.cloneDeep(columns) )
    }
  },

  /**
   * @name _customDataAxiosGetLinkedTablesValues
   * @description get Linked table for customDataAxiosGetFilters ACTION
   * @param commit
   * @param dispatch
   * @param state
   * @param modelName
   * @param modelField
   * @param linkedColumn
   * @param bot_id
   * @returns {Promise<void>}
   * @private
   */
  async _customDataAxiosGetLinkedTablesValues({ commit, dispatch, state }, {modelName, modelField, linkedColumn, bot_id}) {
    if(!modelName && !linkedColumn) return

    const token = Vue.$cookies.get('token_e')
    const engine_url = state.engine_settings.active_url;
    const url = `${engine_url}/kw/custom_modules/${modelName}/list?`

    const params = {
      bot_id: bot_id,
    }

    dispatch('updateAjaxDialog', [true, true]);

    await axios({
      method: 'POST',
      url: url,
      headers: {
        'X-CSRF-TOKEN': token,
      },
			params: params,
			data: {
				offset: 0,
				limit: 10000,
			},
    })
      .then( (response) => {
          dispatch('updateAjaxDialog', [false, false]);

          if ( response?.data?.status === 'success' ) {
            const rows = response?.data?.data?.rows

            let arr = []
            if(rows.length) {
              let name = '';

              if (modelField) {
                name = modelField
              } else {
                const objFirstItem = rows[0]
                name = Object.keys(objFirstItem).find(function(item) {
                  if ( RegExp('name').test(item) ) {
                    return item
                  }
                })
              }


              for (let i in rows) {
                let obj = {
                  name: rows[i][name],
                  id: rows[i].id,
                }

								//Add Color if exist in model name '__color'
								Object.keys(rows[i]).find(function(item) {
									if ( RegExp('__color').test(item) ) obj['color'] = rows[i][item]
								})
								//end Add Color if exist

                if (rows[i][name]) {
                  arr.push(obj)
                }
              }
            }

            commit('CUSTOM_DATA_SET_LINKED_TABLE_VALUES', {
              column: linkedColumn,
              values: arr
            })
          }
        },
        (err) => {
          this.$store.dispatch('updateAjaxDialogError', [ err, 'Error', 'Linked Table ERROR' ]);
        }
      );
  },

  customDataClearLinkedTableValues({ commit }) {
    commit('CUSTOM_DATA_CLEAR_LINKED_TABLE_VALUES');
  },

  customDataSetEmptySelectedFilter({ commit }, {key, values}) {
    commit('CUSTOM_DATA_SET_EMPTY_SELECTED_FILTER', {key, values})
  },

  customDataSetSelectedFilter({ commit }, {key, values}) {
    commit('CUSTOM_DATA_SET_SELECTED_FILTER', {key, values})
  },

  customDataSetSelectedFilterValue({ commit }, {key, value}) {
    commit('CUSTOM_DATA_SET_SELECTED_FILTER_VALUE', {key, value})
  },
  customDataSetSelectedFilterCondition({ commit }, {key, condition}) {
    commit('CUSTOM_DATA_SET_SELECTED_FILTER_CONDITION', {key, condition})
  },

  customDataDeleteSelectedFilter({ commit }, key) {
    commit('CUSTOM_DATA_DELETE_SELECTED_FILTER', key)
  },
  customDataDeleteSelectedFilterInArray({ commit }, {key, value}) {
    commit('CUSTOM_DATA_DELETE_SELECTED_FILTER_IN_ARRAY', {key, value})
  },
  customDataSetSelectedFiltersEmpty({ commit }) {
    commit('CUSTOM_DATA_SELECTED_FILTERS_CLEAR_VALUES')
  },

  customDataSetColumnsData({ commit }, data) {
    let arr = []
    if (data.length && data[0].hasOwnProperty('position')) {
      arr = data
    } else if(data.length) {
      for (let i in data) {
        if(data[i].hidden_all) continue;
        let item = {
          ...data[i],
          active: !data[i].hidden_in_table,
          position: Number(i),
          sort: null,
        }

        arr.push(item)
      }
    }

    commit('CUSTOM_DATA_SET_COLUMNS_DATA', arr)
  },
  /**
   * @name customDataSetDefaultColumnsData
   * @description save Default Columns in state
   * @param commit
   * @param data
   */
  customDataSetDefaultColumnsData({ commit }, data) {
    let arr = []
    for (let i in data) {
      if(data[i].hidden_all) continue;
      let item = {
        ...data[i],
        active: !data[i].hidden_in_table,
        position: Number(i),
        sort: null,
      }

      arr.push(item)
    }
    commit('CUSTOM_DATA_SET_DEFAULT_COLUMNS_DATA', arr)
  },

  customDataSetColumnsDataFromActivated({ getters, commit }, mode) {
      if (mode) {
        commit('CUSTOM_DATA_SET_COLUMNS_DATA', [])
        commit('CUSTOM_DATA_SET_COLUMNS_DATA_ACTIVATED', [])
      } else {
        commit('CUSTOM_DATA_SET_COLUMNS_DATA', getters.custom_data_columns_data_activated)
      }
  },

  customDataSetToggleActiveFilter({ dispatch, commit }, value) {
    commit('CUSTOM_DATA_SET_TOGGLE_ACTIVE_FILTER', value)
    dispatch('customDataActivateSavedFilter', value)
  },

  //Columns ACTIONS
  customDataToggleColumnActive({ commit }, {name, value}) {
    commit('CUSTOM_DATA_TOGGLE_COLUMN_ACTIVE', {name, value})
  },
  customDataChangeOrderColumn({ state, commit }, data) {
    commit('CUSTOM_DATA_CHANGE_ORDER_COLUMN', data)
  },

  /**
   * @name customDataAxiosSaveColumnsData
   * @description Save columns for Model
   * @param state
   * @param dispatch
   * @param commit
   * @param name
   * @param reset
   * @returns {Promise<void>}
   */
  async customDataAxiosSaveColumnsData({ state, dispatch, commit, }, {name, reset}) {
    const token = Vue.$cookies.get('token_e')
    const engine_url = state.engine_settings.active_url;
    const bot_id = state.bot_settings.active_id;
    const columns_chooser =
      reset ? {} : state.custom_data.columns_data_activated

    const url = `${engine_url}/kw/custom_modules/update_columns_chooser`

    let request = {
      bot_id,
      name,
      columns_chooser,
    }
    dispatch('updateAjaxDialog', [true, true]);

    await axios.post(url, request, {
      headers: {
        "X-CSRF-TOKEN": token
      },
    })
      .then( async (response) => {
          dispatch('updateAjaxDialog', [false, false]);
          if ( response?.data?.status === 'success' ) {
            dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.columns_chooser.columns_saved_success') ]);
          }
        },
        (err) => {
          dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_save") ]);
        }
      );
  },
  async _customDataSetSavedColumnsData({commit}, payload) {
    await commit('CUSTOM_DATA_SET_SAVED_COLUMNS_DATA', payload)
  },
  customDataResetColumnsDataToDefault({ state, commit, dispatch }, name) {
    dispatch('customDataAxiosSaveColumnsData', {name: name, reset: true})
    Vue.set(state.custom_data, 'columns_data', state.custom_data.default_columns_data)
  },

  customDataResetColumnsDataForFilterToSaved({ state, commit }) {
    let columns;

    (state.custom_data.saved_columns_data?.length)
      ? columns = _.cloneDeep(state.custom_data.saved_columns_data)
      : columns = _.cloneDeep(state.custom_data.default_columns_data)

    commit('CUSTOM_DATA_SET_COLUMNS_DATA', columns)
    // Vue.set(state.custom_data, 'columns_data', state.custom_data.saved_columns_data)
  },
  //end Columns ACTIONS
};

const getters = {
    custom_data_tables: (state)=>{
      return state.custom_data.tables
    },

   custom_data_tables_select: (state)=>{
    let tables = []
       for( let t in state.custom_data.tables){
           tables.push({id: state.custom_data.tables[t].id, name:state.custom_data.tables[t].name})
       }
  return tables
  },

  custom_data_filters: (state) => state.custom_data.filters,
  custom_data_linked_tables_values: (state) => state.custom_data.linkedTablesValues,
  custom_data_selected_filters: (state) => state.custom_data.selected_filters,
  custom_data_saved_filters: (state) => state.custom_data.saved_filters,

  custom_data_toggle_active_filter: (state) => state.custom_data.toggle_active_filter,

  custom_data_columns_data: (state) => state.custom_data.columns_data,
  custom_data_columns_data_activated: (state) => state.custom_data.columns_data_activated,
  custom_data_saved_columns_data: (state) => state.custom_data.saved_columns_data,
  custom_data_default_columns_data: (state) => state.custom_data.default_columns_data,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
