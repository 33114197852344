<template>

    <div>

        <template v-if="itemName === 'id'">
            {{ value }}
        </template>

        <template v-else-if="is_association">
            <v-select
                dense
                solo
                flat
                hide-details
                attach
                :background-color="getColorSelect || 'transparent'"
                class="colored_select"
                append-icon=""
                item-text="name"
                item-value="id"
                v-model="item_value"
                :items="custom_data_linked_tables_values[itemName]"
                :menu-props="{minWidth:'200'}"
                @change="triggerChange($event)"
            >
              <template v-slot:item="data">
                <span :style="{'background-color': data.item.color}">
                  {{data.item.name}}
                </span>
              </template>
            </v-select>
        </template>

        <template v-else>
            {{/*STRING, TEXT, FLOAT FIELDS*/}}
            <div style="position: relative;">
                <v-tooltip
                    v-if="['STRING', 'TEXT', 'FLOAT', 'INTEGER'].includes(itemType)"
                    attach
                    bottom
                    :disabled="!value"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :append-icon="fieldIcon()"
                            :value="value"
                            solo
                            :readonly="readonlyMode || itemEditable"
                            :class="{'non-editable': itemEditable, 'is-link': itemDisplayType === 'link', 'is-open': itemClickAction === 'open'}"
                            hide-details
                            background-color="transparent"
                            flat
                            single-line
                            dense
                            @click:append="triggerClick()"
                            @click="triggerClick()"
                            @change="triggerChange($event)"
                            v-bind="attrs"
                            v-on="on"
                        />
                    </template>
                    <span>{{ value }}</span>
                </v-tooltip>
            </div>

            {{/*BOOL FIELDS*/}}
            <v-checkbox
                v-if="['BOOLEAN'].includes(itemType)"
                v-model="item_value_checkbox"
                hide-details
                class="mb-4"
            ></v-checkbox>

            {{/*DATE FIELDS*/}}
            <template
                v-if="['DATE'].includes(itemType)"
            >

                <v-tooltip
                    v-if="itemName === 'createdAt'"
                    left
                    attach
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip 
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        >
                            {{ formatDate(value, true) }}
                        </v-chip>
                    </template>
                    <span>{{ formatDate(value) }}</span>
                </v-tooltip> 

                <div 
                    v-else
                    class="calendar-cell"
                    style="position: relative;"
                >

                    <v-menu
                        :close-on-content-click="true"
                        transition="scale-transition"
                        attach
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                dense
                                solo
                                flat
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                                hide-details
                                background-color="transparent"
                                :value="formatDate(item_value_date, false, true)"
                                @input="triggerChange($event)"
                            ></v-text-field>
                        </template>

                        <v-date-picker
                            v-model="item_value_date"
                            no-title
                            :locale="i18n.locale"
                            scrollable
                        >
                        </v-date-picker>
                    </v-menu>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import i18n from "@/lang/i18n";
import {mapGetters} from "vuex";

export default {
  name: "DynamicTableCell",

  props: {
        itemId: {
            type: Number
        },
        itemName: {
            type: String
        },
        value: {
            type: [String, Number],
            default: ''
        },
        itemType: {
            type: String
        },
        itemEditable: {
            type: Boolean,
            default: false
        },
        itemDisplayType: {
            type: String
        },
        itemClickAction: {
            type: String
        }
    },

    data: () => ({
        i18n,
        readonlyMode: true
    }),

    computed: {
      ...mapGetters([
          'custom_data_linked_tables_values',
      ]),

      item_value: {
        get: function() {
          const arr = this.custom_data_linked_tables_values[this.itemName]
          return arr.find((i) => i.name === this.value)
        },
        set: function(val){
          this.triggerChange(val)
        }
      },

      getColorSelect() {
        return this.item_value?.color || false
      },

        is_association() {
            return !!this.custom_data_linked_tables_values?.[this.itemName];
        },

        item_value_date: {
            get: function() {
                return this.value
            },
            set: function(val){
                this.triggerChange(val)
            }
        },

        item_value_checkbox: {
            get: function() {
                return this.value
            },
            set: function(val){
                this.triggerChange(val)
            }
        },
    },

    methods: {
        triggerClick() {
            this.readonlyMode = false;

            if (this.itemDisplayType === 'link') {
                window.open(this.addHttpIfMissing(this.value));
            }

            if (this.itemClickAction === 'open') {
                this.$emit('open-edit', this.itemId);
            }
        },

        async triggerChange(value) {
          const request = {
            [this.itemName]: value,
            id: this.itemId
          }

          await this.$root.$emit('CustomDataTab', request)
        },

        formatDate(isoTimeString, short = false, shortest = false) {
            if (!isoTimeString) return;
            const date = new Date(isoTimeString);
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');

            if (short) {
                return `${day}.${month}.${year.toString().slice(-2)} ${hours}:${minutes}`;
            } else if (shortest) {
                return `${day}.${month}.${year}`;
            } else {
                return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
            }
        },

        addHttpIfMissing(url) {
            if (!url.startsWith("http://") && !url.startsWith("https://")) {
                url = "http://" + url;
            }
            return url;
        },

        fieldIcon() {
            return this.itemClickAction === 'open' ? 'mdi-menu-open' : this.itemDisplayType === 'link' && this.value ? 'mdi-open-in-new' : '';
        }
    }

}

</script>

<style scoped lang="scss">
    .v-input::v-deep {

        &:not(.non-editable) {
            transition: all 0.25s;
            border: 1px solid transparent;

            &:hover {
                background-color: var(--v-primaryLight-base);
            }

            &.v-input--is-focused {
                border-color: var(--v-blueHaze-base);
            }
        }

        &.is-link,
        &.is-open {
            .v-input__slot, input {
                cursor: pointer;
            }

            .v-input__icon {
                opacity: 0;
                transition: all 0.25s;
            }

            &:hover {
                .v-input__icon {
                    opacity: 1;
                }
            }
        }

        &.is-link {
            .v-icon {
                font-size: 22px;
            }
        }

    }

  .colored_select::v-deep {

    .v-list {
        padding: 0;
    }

    .v-list-item {
      padding: 0;

      & > span {
        padding: 0 16px;
        width: 100%;
        min-height: 40px;
        display: flex;
        align-items: center;
      }
    }

    .v-list-item--link:before {
        opacity: 0 !important;
    }
  }

    .calendar-cell ::v-deep {
        table {
            border-top: 1px solid var(--v-blueHaze-base) !important;

            th {
                border-top: none;
            }
        }
    }
</style>