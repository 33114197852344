import Vue from 'vue';
import axios from 'axios/index';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      customdata: {},
    };
  },

  computed: {
    ...mapGetters(['current_tab_type', 'engine_settings', 'bot_settings']),
  },

  mounted() {
    const url = this.engine_settings.active_url;
    const token = Vue.$cookies.get('token_e');
    const bot_id = parseInt(this.bot_settings.active_id);
    const request = {
      bot_id,
    };

  },
};
