import Vue from "vue";
import axios from "axios";
import i18n from "@/lang/i18n.js";

const state = {
  action_jail:{
    actions:[]
  }
};

const mutations = {
    ACTION_JAIL_SET_ACTIONS(state, actions) {
        console.log('ACTION_JAIL_SET_ACTIONS')
        console.log(state)
        console.log(state.action_jail)
    state.action_jail.actions = actions;
  }
};

const actions = {

    /**
     * Get action jail actions
     * @method ActionJailAxiosGetActions
     */
   async ActionJailAxiosGetActions({ commit, dispatch, state }) {
      let success = false;
      const token = Vue.$cookies.get('token_e')
      const engine_url = state.engine_settings.active_url;
      // const bot_id = state.bot_settings.active_id;
      const url = `${engine_url}/kw/actions`
      const params = null

      //dispatch("updateAjaxDialog", [true, true]);
      await axios.get(url, {
        headers: {
          "X-CSRF-TOKEN": token
        },
        params
      })
          .then( async (response) => {
                //dispatch("updateAjaxDialog", [false, false]);

                if ( response?.data?.status === 'success' && response.data?.data?.groups ) {
                  commit('ACTION_JAIL_SET_ACTIONS', response.data.data.groups)
                  success = true;

                  dispatch('updateAjaxDialog', [false, false]);
                }
              },
              (err) => {

              dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_get") ]);
              }
          );

      return success;
    },

    /**
     * Create action jail action
     * @method ActionJailAxiosCreateAction
     * @param name - action name
     * @param body - action body
     *
     */
     async ActionJailAxiosCreateAction({ commit, dispatch, state }, {name, title, description, json_config, body}) {

      let success = false;

      const token = Vue.$cookies.get('token_e')
      const engine_url = state.engine_settings.active_url;
      // const bot_id = state.bot_settings.active_id;
      const user_id = state.user.id;

      const url = `${engine_url}/kw/action_jail/create_update_action`
      const mapping_object = {}

      let request = {
        // bot_id,
        user_id,
        name,
        title,
        description,
        json_config,
        body
      }
      dispatch('updateAjaxDialog', [true, true]);

      await axios.post(url, request, {
        headers: {
          "X-CSRF-TOKEN": token
        },
      })
          .then( async (response) => {
               //dispatch('updateAjaxDialog', [false, false]);
                if ( response?.data?.status === 'success' ) {
                  success = true;
                 console.log('ActionJailAxiosCreateAction success!')
                 dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.action_jail.common.success'), i18n.t('modules.action_jail.action.created') ]);
                }
              },
              (err) => {
               dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_save") ]);
              }
          );

      return success;
    },

    /**
     * Delete action jail action with all data
     * @method ActionJailAxiosDeleteAction
     * @param name - model name
     */
    async ActionJailAxiosDeleteAction({ commit, dispatch, state }, {name}) {

      let success = false;

      const token = Vue.$cookies.get('token_e')
      const engine_url = state.engine_settings.active_url;
      // const bot_id = state.bot_settings.active_id;
      const user_id = state.user.id;

      const url = `${engine_url}/kw/action_jail/delete_action`


      let request = {
        // bot_id,
        user_id,
        name
      }

      console.log(request)
      dispatch('updateAjaxDialog', [true, true]);

      await axios.delete(url, {
        headers: {
          "X-CSRF-TOKEN": token
        },
          data:request
      })
          .then( async (response) => {
               //dispatch('updateAjaxDialog', [false, false]);
                if ( response?.data?.status === 'success' ) {
                 success = true;

                 dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.action_jail.common.success'), i18n.t('modules.action_jail.action.deleted') ]);
                }
              },
              (err) => {
               dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_save") ]);
              }
          );

      return success;
    }

};

const getters = {
      action_jail_actions: (state)=>{
        return state.action_jail.actions
      },

     action_jail_actions_select: (state)=>{
      let actions = []
      let i = 0
      for( let t in state.action_jail.actions){

        //translate "null" group
        if (state.action_jail.actions[t].name === 'null') {
          Vue.set(state.action_jail.actions[t], ['name'], i18n.t('modules.action_jail.group.group_null'))
        }

        //add id to groups
        Vue.set(state.action_jail.actions[t], ['id'], parseInt(t))

        //add id to actions in group
        if (state.action_jail.actions[t]?.children.length > 0) {
          let e = 0
          for (let c in state.action_jail.actions[t]?.children) {
            Vue.set(
              state.action_jail.actions[t].children[c],
              ['id'],
              parseInt(t.toString() + c.toString()))
            e++;
          }
        }

        i++;
      }
      return state.action_jail.actions
      },

      action_jail_actions_list: (state)=>{
        let arr = []

        state.action_jail.actions.forEach(function(group) {
          //translate "null" group
          if (group.name === 'null') {
            group.name = i18n.t('modules.action_jail.group.group_null')
          }

          arr.push({header: group.name})

          if (group.children.length > 0) {
            for (let c in group.children) {
                group.children[c].name = group.children[c].name.replace('action_', '')
                arr.push(group.children[c])
            }
          }
        })

      return arr
      }
};

export default {
  state,
  mutations,
  actions,
  getters,
};
