<template>
	<v-snackbar
    class="modal-transfer-chat-user"
    v-model="isModalOpen"
    multi-line
    text
    outlined
    color="white"
    top
    left
    :timeout="-1"
    :min-width="$vuetify.breakpoint.smAndDown ? '90%' : '500'"
    max-width="520"
    elevation="5"
  >
    <div class="black--text font-weight-bold mb-3">{{i18n.t('modules.op.transfer_modal.title')}}</div>

    <v-alert
      v-if="opIsTransferChatUserModalOpen.data"
      dense
      outlined
      text
      border="left"
      color="primaryLighten"
    >
      <span class="text-caption">
        {{ opIsTransferChatUserModalOpen.data.system_message }}
      </span>
    </v-alert>

    <v-btn
      v-if="!messagesList"
      color="primary"
      small
      @click="showMessages()"
      elevation="0"
      text
    >
      {{i18n.t('modules.op.transfer_modal.show_messages_list')}}
    </v-btn>

    <v-sheet
      height="300"
      max-width="520"
      v-if="messagesList"
      class="mt-3 pt-3"
    >
      <vue-scroll>
        <div
          v-for="(item, i) in messagesList"
          :key="i"
          class="item mx-4 mb-2"
        >
          <v-layout
            v-if="item.type === 'text'"
            class="item-message"
            align-center
            justify-start
          >
            <v-chip
              class="mr-3 item-name"
              color="primary"
            >{{item.author}}</v-chip>
            <div class="item-descr">
              <div class="text-body-2">{{item.data.text}}</div>
              <div class="text-caption greyBlueLight--text" v-text="pretifyDateTime(item.data.time)"></div>
            </div>
          </v-layout>
        </div>
      </vue-scroll>
    </v-sheet>

    <v-layout
      class="mt-3"
      justify-space-between
    >
      <v-btn
        color="success"
        small
        @click="acceptTransfer"
        elevation="0"
      >
        {{ i18n.t('modules.op.transfer_modal.accept_transfer') }} <b class="ml-1" v-if="countDown">({{ countDown }})</b>
      </v-btn>
      <v-btn
        color="error"
        plain
        small
        @click="cancelTransfer"
      >
        {{ i18n.t('modules.op.texts.cancel') }}
      </v-btn>
    </v-layout>
	</v-snackbar>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import i18n from '@/lang/i18n';
import HELPERS from "@/modules/OperatorPanel/helpers";

export default {
	data() {
		return {
			i18n,
      messagesList: null,
      countDown: 0,
		};
	},
	computed: {
		...mapGetters([
			'opIsTransferChatUserModalOpen',
			'opActiveChatRoom',
      'opActiveChatRoomFromState',
		]),

		isModalOpen: {
			get() {
				return this.opIsTransferChatUserModalOpen.open;
			},

			set(state) {
				const options = {
					open: state,
					// data: null,
				};

				this.$store.dispatch('opSetTransferChatUserModalOpen', options);
			},
		},
	},
	methods: {
    ...mapActions(['OpAxiosGetChatRoomMessagesLimit']),

		closeModal() {
			const options = {
				open: false,
				data: null,
			};

			this.$store.dispatch('opSetTransferChatUserModalOpen', options);
      this.messagesList = null
      this.countDown = 0;
		},

    acceptTransfer() {
      const { user_id_receive, chat_room_id, user_id_sender } = this.opIsTransferChatUserModalOpen.data

      this.$socket.client.emit('accept_transfer', {
        user_id_sender: user_id_sender,
        user_id_receive: user_id_receive,
        chat_room_id: chat_room_id
      });
      this.closeModal()

      //set chat active
      if(!this.opActiveChatRoomFromState && this.$route?.name === 'OperatorPanelChats') {
        this.$store.dispatch('opSetActiveChatRoomById', chat_room_id);
      }
    },

    async cancelTransfer() {
      if (!this.opIsTransferChatUserModalOpen?.data) return
      const { chat_room_id, user_id_sender, user_id_receive } = this.opIsTransferChatUserModalOpen?.data

      await this.$socket.client.emit('cancel_transfer', {
        user_id_sender: user_id_sender,
        chat_room_id: chat_room_id,
        user_id_receive: user_id_receive,
      });

      this.closeModal()
    },

    async showMessages() {
      const {chat_room_id, user_id_sender} = this.opIsTransferChatUserModalOpen.data

      const data = await this.OpAxiosGetChatRoomMessagesLimit(chat_room_id)
      this.messagesList = HELPERS.formatDialogMsgs({
        messages: data,
        userId: user_id_sender,
      });
    },
    pretifyDateTime(date) {
      return HELPERS.formatTimeStampToDateTime(date)
    },

    countDownTimer () {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      } else if(this.countDown === 0) {
        this.cancelTransfer()
      }
    }
	},

  watch: {
    isModalOpen: function(val) {
      if (val && !this.opIsTransferChatUserModalOpen?.withoutTimer){
        this.countDown = 120;
        this.countDownTimer();
      }
    }
  },
};
</script>

<style lang="scss">

</style>
