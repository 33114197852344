var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container"},[_c('div',{staticClass:"table-wrapper"},[_c('v-data-table',{staticClass:"elevation-0 table custom-table",class:{'table__without-checkbox': !_vm.select_active},attrs:{"show-select":_vm.select_active,"value":_vm.selected,"headers":_vm.active_headers,"items":_vm.items,"item-key":"id","checkbox-color":"primary","header-props":{ sortIcon: '$vuetify.icons.iconsort' },"fixed-header":"","disable-pagination":"","hide-default-footer":true,"loading":_vm.loading,"loading-text":_vm.loadingItemsText,"options":_vm.options,"must-sort":"","sort-desc":true,"sort-by":_vm.defaultSort,"search":_vm.searchInTable},on:{"input":function($event){return _vm.$emit('update:selected', $event)},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('tr',{attrs:{"data-id":item.id}},[_vm._l((headers),function(header,index){return [(!header.type && _vm.select_active)?_c('td',{key:index},[_c('v-simple-checkbox',{attrs:{"value":isSelected,"color":"primary","ripple":false},on:{"input":function($event){return select($event)}}})],1):(header.name === 'active')?_c('td',{key:index},[_c('v-switch',{attrs:{"inset":"","color":"primary","input-value":item[header.value]},on:{"change":function($event){return _vm.toggleActiveClient({user_id: item['id'], toggle: $event } )}}})],1):(header.name === 'channel')?_c('td',{key:index},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-avatar',{key:_vm.formatChannelIcons(item).name,attrs:{"size":"20"}},[_c('img',{attrs:{"src":_vm.formatChannelIcons(item).icon,"title":_vm.formatChannelIcons(item).name,"alt":_vm.formatChannelIcons(item).name}})])],1)],1):(header.type === 'DATE')?_c('td',{key:index},[_c('DynamicTableCell',{attrs:{"value":item[header.value],"itemId":item.id,"itemName":header.value,"itemType":header.type}})],1):(header.type === 'actions')?_c('td',{key:index},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-btn',{staticClass:"mt-1",attrs:{"icon":"","color":"green","title":_vm.i18n.t('modules.customdata.entity.edit')},on:{"click":function($event){return _vm.editItem(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$iconedit")])],1)],1)],1):_c('td',{key:index},[_c('DynamicTableCell',{attrs:{"value":item[header.value],"itemId":item.id,"itemName":header.value,"itemType":header.type,"itemEditable":header.non_editable,"itemDisplayType":header.display_type,"itemClickAction":header.click_action},on:{"open-edit":_vm.editItem}})],1)]})],2)]}},{key:"body.append",fn:function(ref){
var headers = ref.headers;
return [(_vm.propTotalRow)?_c('tr',{staticClass:"total-row"},_vm._l((headers),function(header,index){return _c('td',{key:index,staticClass:"total-col",class:{'total-col--hidden': header.value == 'data-table-select' || header.value == 'id' || header.value == 'actions'}},[(header.value !== 'data-table-select' && header.value !== 'id' && header.value !== 'actions')?[_vm._v(" "+_vm._s(_vm.getColumnSumByHeader(header))+" ")]:_vm._e()],2)}),0):_vm._e()]}}])})],1),(_vm.selected.length < 1)?_c('div',{staticClass:"table-pagination"},[_c('div',{staticClass:"text-caption mr-5"},[_vm._v(" "+_vm._s(_vm.$t('modules.customdata.table.pagination.showed'))+": "+_vm._s(_vm.itemsLength)+" "+_vm._s(_vm.$t('modules.customdata.table.pagination.items'))+" ")]),_c('v-spacer'),_vm._v(" "+_vm._s(/* Select Per Page */)+" "),_c('div',{staticClass:"d-flex align-center"},[_c('v-select',{staticClass:"select-per-page",attrs:{"item-text":"name","item-value":"value","items":_vm.itemsPerPage,"dense":"","outlined":"","height":"32","hide-details":""},on:{"change":_vm.resetPagination},model:{value:(_vm.selectDefaultPerPage),callback:function ($$v) {_vm.selectDefaultPerPage=$$v},expression:"selectDefaultPerPage"}})],1),_vm._v(" "+_vm._s(/* end Select Per Page */)+" "),_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":(_vm.pageCount >= 3) ? '5' : '0',"circle":""},on:{"input":_vm.handlePageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_c('div',{staticClass:"table-pagination"},[_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t('modules.customdata.table.pagination.selected'))+": "+_vm._s(_vm.selected.length)+" "+_vm._s(_vm.$t('modules.customdata.table.pagination.items'))+" ")]),_c('v-spacer')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }