import Vue from 'vue';
// import _ from 'lodash'
import axios from 'axios/index';
import { extra_modules_list } from '@/modules/custom_modules_list';
import i18n from '@/lang/i18n';
import { router } from "@/main";

//Настройки табов модулей - базовые
let modules = {
  dialog: {
    use_tabs: true,
    tabs_items: [
      {
        tab: i18n.t('modules.dialog.module_settings.dialog'),
        content: '',
        show: true,
        type: 'dialog',
        permits: { show: 'show_dialog_tab' },
        route: 'bot_scenario',
      },
      {
        tab: i18n.t('modules.dialog.module_settings.menu'),
        content: '',
        show: false,
      },
      // {
      //   tab: i18n.t('modules.dialog.module_settings.constants'),
      //   content: '',
      //   show: true,
      //   type: 'constants',
      //   permits: { show: 'show_dialog_constants_tab' },
      //   route: 'constants',
      // },
    ],
  },
  settings: {
    use_tabs: true,
    tabs_items: [
      {
        tab: i18n.t('modules.dialog.module_settings.bots_list'),
        content: '',
        show: true,
        type: 'bots',
        route: 'bots',
      },
      {
        tab: i18n.t('modules.dialog.module_settings.bot_groups'),
        content: '',
        show: true,
        type: 'bot-groups',
        route: 'bot-groups',
      },
      {
        tab: i18n.t('modules.dialog.module_settings.roles_list'),
        content: '',
        show: true,
        type: 'roles',
        route: 'roles',
      },
      {
        tab: i18n.t('modules.dialog.module_settings.users_list'),
        content: '',
        show: true,
        type: 'users',
        route: 'users',
      },
			{
				tab: i18n.t('modules.dialog.module_settings.servers_list'),
				content: '',
				show: true,
				type: 'engines',
				route: 'engines',
			},
			{
				tab: i18n.t('modules.dialog.module_settings.instance_settings'),
				content: '',
				show: true,
				type: 'instance-settings',
				route: 'instance-settings',
			},
    ],
  },
};

//Настройки табов модулей - из модулей

//Список всех урлов модулей
let modules_url_list = [];
//Объект роутов модулей - ключ это название модуля, значение - алиас без слешей в начале и в конце
let modules_routes_obj = {};
let modules_permits_obj = {};

if (extra_modules_list) {
  //Цикл по модулям
  for (let module of extra_modules_list) {
    if (module && module.active && module.component_name) {
      try {
        let obj = require('@/modules/' +
          module.component_name +
          '/kwizbot_settings.js');
        if (obj && obj.default && obj.default.module_settings) {
          let module_settings = obj.default.module_settings;

          if (module_settings) {
            if (module_settings.tabs_items) {
              Object.entries(module_settings.tabs_items).forEach(
                ([key, value]) => {
                  const moduleName = module_settings.menu_items.find(
                    (item) => item.module === key
                  );
                  modules[moduleName.route] = value;
                }
              );
            }

            //Если есть пункты меню
            if (module_settings.menu_items) {
              for (let menu_item of module_settings.menu_items) {
                if (menu_item.alias && menu_item.module && menu_item.title) {
                  //Алиас модуля без слешей в начале и в конце
                  let module_alias = menu_item.alias.replace(/^\/|\/$/g, '');

                  if (
                    module_settings.tabs_items &&
                    module_settings.tabs_items[menu_item.module] &&
                    module_settings.tabs_items[menu_item.module].tabs_items
                  ) {
                    //Разрешения модуля
                    let permits = null;
                    if (menu_item.permits) {
                      permits = menu_item.permits;
                    }
                    modules_permits_obj[menu_item.module] = {
                      permits,
                    };

                    //Объект под роуты модуля
                    modules_routes_obj[menu_item.module] = {};

                    let tab_items =
                      module_settings.tabs_items[menu_item.module].tabs_items;

                    //Цикл по табам
                    for (let tab_item of tab_items) {
                      if (tab_item.show && tab_item.alias && tab_item.type) {
                        //Алиас таба без слешей в начале в конце
                        let tab_alias = tab_item.alias.replace(/^\/|\/$/g, '');
                        //Полный алиас с алиасом модуля
                        let full_alias = (
                          module_alias +
                          '/' +
                          tab_alias
                        ).replace(/^\/|\/$/g, '');

                        //Добавление пути урла в массив для быстрого поиска
                        modules_url_list.push(full_alias);

                        //Определение тайтла документа из модуля и таба
                        let title = menu_item.title;
                        if (tab_item.tab) {
                          title += ': ' + tab_item.tab;
                        }

                        //Добавление в объект роутов путь урла и тайтл документа
                        modules_routes_obj[menu_item.module][tab_item.type] = {
                          path: full_alias,
                          title,
                        };
                      }
                    }
                  }
                }
              }
            }

            if (module_settings.tabs_items) {
              modules = Object.assign(modules, module_settings.tabs_items);
            }
          }
        }
      } catch (e) {
        //console.log(e);
      }
    }
  }
}

const state = {
  //Данные глобального пользователя
  user: {},

  //Главное меню
  menu_items: [
    {
      title: i18n.t('modules.dialog.module_settings.dialog'),
      module: 'dialog',
      icon: '$iconmenudialog',
      show: true,
      permits: {
        show: 'show_dialog_menu_item',
      },
      route: 'dialog',
    },
    {
      title: i18n.t('modules.dialog.module_settings.settings'),
      module: 'settings',
      icon: '$iconmenucog',
      show: true,
      permits: {
        show: 'show_dialog_setting_menu_item',
      },
      route: 'settings',
    },
  ],

  //Модули и табы
  current_module: null, //dialog, statistics, settings
  modules,
  modules_routes_obj,
  tab_type: null,
  //Модули и табы

  //Settings
  engines: [],
  bots: [],
  botsGroups: [],
  languages: [],
  timezone: null,

  engine_settings: {
    active_id: null,
    active_connection_status: false,
    active_url: null,
  },

  bot_settings: {
    active_id: null,
    updatedAt: null,
    default_language_code: null,
    json_data:{}
  },

	instance_settings: [],
  //Settings
};

const actions = {
  //инициация приложения
  async loadData({ commit, dispatch, state, getters }, mode) {
     // console.log('<constructor> [INFO] kwizbot_store loadData');
        dispatch('updateAjaxDialog', [true, true]);
    // await dispatch('axiosGetOwnInfo')

    await dispatch('loadUserData');
    //Получение всех движков и ботов активного движка
    await dispatch('axiosGetEnginesAndBotsAndLanguages');

		//get Instance Settings
		await dispatch('getAxiosInstanceSettings')

    //Роуты модулей
    //TODO: review it!
    const url = new URL(window.location.href);

    //текущий путь относительно корня
    if (url.pathname) {
      //Получение пути из урла без слешей вначале и в конце
      let path = url.pathname.replace(/^\/|\/$/g, '');

      const names = {
        OperatorPanel: 'operatorPanel',
      };

      const compare = {
        OperatorPanel: 'operatorPanel',
        OperatorPanel_settings: 'operatorPanel.settings',
        FileManager: 'fileManager',
        Statistics: 'statistics',
        Statistics_settings: 'statistics.settings',
        Polls: 'polls',
        Bids: 'bids',
        ActionJail: 'action_jail',
        FastLine: 'fast_line',
      };

      if (path === '') {
        const hasScenarioPermisions =
          state.user.role?.permissions?.scenario?.route?.haveRights;

        if (hasScenarioPermisions) {
          //Установка тайтла документа, установка модуля и установка таба по умолчанию
          document.title = 'Kwizbot';
          dispatch('setModule', 'dialog');
          dispatch('setTabType', 'dialog');
          window.history.pushState('scenario', 'title', '/');
          return true;
        }

        for (let module_name in modules_routes_obj) {
          //Если модуль найден
          if (
            state?.user?.role?.permissions[compare[module_name] || module_name]
              ?.route?.haveRights
          ) {
            if (module_name && modules_routes_obj[module_name]) {
              let module = modules_routes_obj[module_name];
              if (module) {
                let module_access = true;

                //Модуль доступен - переводим на него
                if (module_access) {
                  for (let tab_type in module) {
                    if (tab_type && module[tab_type]) {
                      let tab = module[tab_type];
                      if (tab.title && tab.path) {
                        //Установка тайтла документа, установка модуля и установка таба
                        document.title = tab.title;
                        dispatch('setModule', module_name);
                        dispatch('setTabType', tab_type);
                        window.history.pushState(
                          module_name,
                          'title',
                          tab.path
                        );
                        return true;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      //Routes from modules
      if (path && modules_url_list.includes(path) && modules_routes_obj) {
        for (let module_name in modules_routes_obj) {
          //Если модуль найден
          if (module_name && modules_routes_obj[module_name]) {
            //цикл по модулям
            for (let tab_type in modules_routes_obj[module_name]) {
              //если путь урла таба совпадает с путем урла браузера
              if (
                tab_type &&
                modules_routes_obj[module_name][tab_type] &&
                modules_routes_obj[module_name][tab_type].path === path
              ) {
                //Установка тайтла документа, установка модуля и установка таба
                document.title =
                  modules_routes_obj[module_name][tab_type].title;
                dispatch('setModule', module_name);
                dispatch('setTabType', tab_type);

                // console.log('module_name, tab_type', module_name, tab_type);

                return true;
              }
            }
          }
        }
      }
    }

    //По умолчанию - диалог //todo решить что с этим делать
    // dispatch('setModule', 'dialog')
    // dispatch('setTabType', 'dialog')
  },

  //Получение списка движков, ботов, языков
  async axiosGetEnginesAndBotsAndLanguages({ commit, dispatch, state }) {
    await dispatch('axiosGetEngines');

    if (state.engine_settings.active_id) {
      await dispatch('axiosActiveEngineConnect');

    //Получение всех ботов активного движка
      await dispatch('axiosGetBotsAndLanguages');
    }

    // dispatch('checkCurrentBot');
  },

  //Получение списка движков
  async axiosGetEngines({ commit, dispatch, state }) {
    let token = Vue.$cookies.get('token_c');

    await axios
      .get(process.env.VUE_APP_CONSTRUCTOR_URL + '/kw/engines/', {
        headers: {
          'X-CSRF-TOKEN': token,
        },
      })
      .then(
        (response) => {
          if (response.data && response.data.data) {
            if (response.data.data && response.data.data.engines) {
              let engines = response.data.data.engines;
              for (let engine of engines) {
                if (engine.active && engine.id && engine.engine_url) {
                  commit('SET_ENGINE_SETTING', {
                    field: 'active_id',
                    value: engine.id,
                  });
                  commit('SET_ENGINE_SETTING', {
                    field: 'active_url',
                    value: engine.engine_url,
                  });
                }
              }
              commit('SET_ENGINES', engines);
            }
          }else{
            console.log('<constructor>[ERROR] engine list is not loaded')
            dispatch('updateAjaxDialogError',  ['Error', 'Engine list is not loaded']);

          }
        },
        (err) => {
          console.log('<constructor>[ERROR] engine list is not loaded')
          dispatch('updateAjaxDialogError', [err, 'Error', 'Engine list is not loaded']);
        }
      );
  },


  /**
   * Sets connection state and saves engine token to cookie
   * @method axiosActiveEngineConnect
   */
  async axiosActiveEngineConnect({ commit, dispatch, state }) {
    let request = {
      id: parseInt(state.engine_settings.active_id),
    };

    let token = Vue.$cookies.get('token_c');

    await axios
      .post(
        process.env.VUE_APP_CONSTRUCTOR_URL + '/kw/get_engine_token/',
        request,
        {
          headers: {
            'X-CSRF-TOKEN': token,
          },
        }
      )
      .then(
        (response) => {
          if (response.data && response.data.data && response.data.data.token) {
            Vue.$cookies.set(
              'token_e',
              response.data.data.token,
              60 * 60 * 24 * 30
            );
            commit('SET_ENGINE_SETTING', {
              field: 'active_connection_status',
              value: true,
            });
          }
        },
        (err) => {
          // this.$store.dispatch('updateAjaxDialogError', [err, 'Ошибка', 'Не удалось активировать сервер']);
        }
      );
  },

  /**
   * Get list of bots and languages
   * @method axiosGetBotsAndLanguages
   */
  async axiosGetBotsAndLanguages({ commit, dispatch, state }) {

    //Получение ботов
    await dispatch('axiosGetBots');
    await dispatch('axiosGetBotsGroups');
    // if (state.bot_settings.active_id) {
      //Получение всех языков активного бота
      // await dispatch('axiosGetBotLanguages');
    // }
  },

  //Получение списка ботов
  async axiosGetBots({ commit, dispatch, state }) {
    //todo eb удалить методы работы с куками из плагина
    let token = Vue.$cookies.get('token_e');
    let url = state.engine_settings.active_url;

    if (url && token && state.engine_settings.active_id) {
      await axios
        .get(url + '/kw/bots/', {
          headers: {
            'X-CSRF-TOKEN': token,
          },
        })
        .then(
          (response) => {
            if (response.data && response.data.data) {

              if(response.data.data && response.data.data.timezone) {
                commit('SET_TIMEZONE', response.data.data.timezone);
              }

              if (response.data.data && response.data.data.bots) {
                let bots = response.data.data.bots;

								dispatch('_setActiveBotCookies', bots)
                dispatch("updateAjaxDialog", [false, false]);
              }
            }else{
               console.log('<constructor>[ERROR] bots list is not loaded')

            dispatch('updateAjaxDialogError', [
              'Error',
              'Bots list is not loaded',
            ]);
            }
          },
          (err) => {

            console.log('<constructor>[ERROR] bots list is not loaded')

            dispatch('updateAjaxDialogError', [
              err,
              'Error',
              'Bots list is not loaded',
            ]);

          }
        );
    }
  },

	async _setActiveBotCookies({ commit, dispatch }, bots) {
		//check if active bot not exist
		if (!bots.find((b) => b.active) && bots.length) bots[0].active = true

		const params = {
			active_id: null,
			updatedAt: null,
			json_data: null,
		}
		const activeBot = bots.find((b) => b.active)
		const storage = sessionStorage.getItem('active_bot_settings')
		const cookie = Vue.$cookies.get('active_bot_settings')


		if(
			storage &&
			!!bots.find((b) => b.id === JSON.parse(sessionStorage.getItem('active_bot_settings')).active_id)
		) {
			const storageData = JSON.parse(sessionStorage.getItem('active_bot_settings'))

			params.active_id = storageData.active_id
			params.updatedAt = storageData.updatedAt
			params.json_data = storageData.json_data
		} else if (
			cookie &&
			!!bots.find((b) => b.id === Vue.$cookies.get('active_bot_settings').active_id)
		) {
			const cookieData = Vue.$cookies.get('active_bot_settings')

			params.active_id = cookieData.active_id
			params.updatedAt = cookieData.updatedAt
			params.json_data = cookieData.json_data
		} else {
			params.active_id = activeBot.id
			params.updatedAt = activeBot.updatedAt
			params.json_data = activeBot.json_data
		}

		await dispatch('_commitActiveBotCookies', params)
		await commit('SET_BOTS', bots);

	},
	async _commitActiveBotCookies({ commit }, params) {
		sessionStorage.setItem( 'active_bot_settings', JSON.stringify(params) );
		Vue.$cookies.set(
			'active_bot_settings',
			params,
			60 * 60 * 24 * 30
		);

		await commit('SET_BOT_SETTING', { field: 'active_id', value: params.active_id });
		await commit('SET_BOT_SETTING', { field: 'updatedAt', value: params.updatedAt });
		await commit('SET_BOT_SETTING', { field: 'json_data', value: params.json_data });
	},

  async axiosGetBotsGroups({ commit, dispatch, state }) {
    //todo eb удалить методы работы с куками из плагина
    let token = Vue.$cookies.get('token_e');
    let url = state.engine_settings.active_url;

    if (url && token && state.engine_settings.active_id) {
      await axios
        .get(url + '/kw/groups/', {
          headers: {
            "Access-Control-Allow-Credentials": '*',
            'X-CSRF-TOKEN': token,
          },
        })
        .then(
          (response) => {
            if (response.data && response.data.data) {
              if (response.data.data && response.data.data.groups) {
                let groups = response.data.data.groups;
                commit('SET_BOTS_GROUPS', groups);
              }
            }else{
              console.log('<constructor>[ERROR] bots Groups list is not loaded')

              dispatch('updateAjaxDialogError', [
                'Error',
                'Bots Groups list is not loaded',
              ]);
            }
          },
          (err) => {

            console.log('<constructor>[ERROR] bots list is not loaded')

            dispatch('updateAjaxDialogError', [
              err,
              'Error',
              'Bots Groups list is not loaded',
            ]);

          }
        );
    }
  },

  //Получение списка языков активного бота
  // async axiosGetBotLanguages({ commit, dispatch, state }) {
  //   commit('SET_BOT_SETTING', { field: 'default_language_code', value: null });
	//
  //   let token = Vue.$cookies.get('token_e');
  //   let url = state.engine_settings.active_url;
  //   let bot_id = state.bot_settings.active_id;
	//
  //   if (url && token && state.engine_settings.active_id && bot_id) {
  //     await axios
  //       .get(url + '/kw/languages/' + bot_id + '/', {
  //         headers: {
  //           'X-CSRF-TOKEN': token,
  //         },
  //       })
  //       .then(
  //         (response) => {
  //           if (response?.data?.data?.languages) {
	//
  //               let languages = response.data.data.languages;
  //               for (let lang of languages) {
  //                 if (lang.default && lang.code) {
  //                   commit('SET_BOT_SETTING', {
  //                     field: 'default_language_code',
  //                     value: lang.code,
  //                   });
  //                 }
  //               }
	//
  //               commit('SET_LANGUAGES', languages);
  //               dispatch('updateAjaxDialog', [false, false]);
	//
  //             }else{
  //              dispatch('updateAjaxDialogError', [
  //             'Error',
  //             i18n.t('modules.dialog.texts.settings.error_language_list')
  //           ]);
  //           }
	//
  //         },
  //         (err) => {
  //           dispatch('updateAjaxDialogError', [
  //             err,
  //             'Error',
  //             i18n.t('modules.dialog.texts.settings.error_language_list')
  //           ]);
  //         }
  //       );
  //   }
  // },

  /**
   * If all_bot_data_init is not ready, set module to settings
   * @method checkCurrentBot
   */
  async checkCurrentBot({ commit, dispatch, state, getters }) {
    let check_res = getters.all_bot_data_init;

    if (!check_res.ready) {
      dispatch('setModule', 'settings');
    }
  },
  //Настройки

  //Меню
  setModule({ commit, state }, module) {
    commit('SET_MODULE', module);
  },

  setTabType({ commit, state, getters }, tab_type) {
    commit('SET_TAB_TYPE', tab_type);
  },
  //Меню

  /**
   * Set active bot
   * @method setActiveBot
   * @param id
   */
  setActiveBot({state, commit, dispatch}, id) {
    let url = state.engine_settings.active_url;
    console.log(url, 'URL')
    let request = {
      id: id,
    };
    let token = Vue.$cookies.get('token_e');

    commit('SET_BOT_SETTING', {
      field: 'active_id',
      value: id,
    });

    dispatch('updateAjaxDialog', [true, true]);
    axios
      .post(url + '/kw/activate_bot/', request, {
        headers: {
          'X-CSRF-TOKEN': token,
        },
      })
      .then(
        (response) => {
          if (
            response.data &&
            response.data.status &&
            response.data.status === 'success'
          ) {
            dispatch('axiosGetBotsAndLanguages');

          }
        },
        (err) => {
          dispatch('updateAjaxDialogError', [
            err,
            i18n.t('common.error'),
            i18n.t(
              'modules.dialog.texts.settings.cant_activate_bot'
            ),
          ]);
        }
      );
  },

	async getAxiosInstanceSettings({state, commit, dispatch}) {
		const token = Vue.$cookies.get('token_c');
		const url = state.engine_settings.active_url;

		dispatch('updateAjaxDialog', [true, true]);

		return await axios
			.get(url + '/kw/instance/settings/list', {
				headers: {
					'X-CSRF-TOKEN': token,
				},
			})
			.then(
				(response) => {
					dispatch('updateAjaxDialog', [false, false]);
					if (response.data && response.data.data?.settings) {
						commit('SET_INSTANCE_SETTINGS', response.data.data.settings)
						return response.data.data.settings
					}
				},
				(err) => {
					console.log('<constructor>[ERROR] Instance Settings is not loaded')
					dispatch('updateAjaxDialogError', [err, 'Error', 'Instance Settings is not loaded']);
				}
			);
	},

	async setAxiosInstanceSetting({state, dispatch}, data) {
		const {key, value} = data

		const token = Vue.$cookies.get('token_c');
		const url = state.engine_settings.active_url;

		let request = {
			key: key,
			value: value
		};

		dispatch('updateAjaxDialog', [true, true]);
		await axios
			.post(
				url + '/kw/instance/settings/create_update',
				request,
				{
				headers: {
					'X-CSRF-TOKEN': token,
				},
			})
			.then(
				(response) => {
					dispatch('updateAjaxDialog', [false, false]);
					dispatch('getAxiosInstanceSettings')
				},
				(err) => {
					console.log('<constructor>[ERROR] Instance Settings is not Set')
					dispatch('updateAjaxDialogError', [err, 'Error', 'Instance Settings is not set']);
				}
			);

	},

	async deleteAxiosInstanceSetting({state, dispatch}, key) {
		const token = Vue.$cookies.get('token_c');
		const url = state.engine_settings.active_url;

		dispatch('updateAjaxDialog', [true, true]);
		await axios
			.delete(
				url + '/kw/instance/settings/delete',
				{
					headers: {
						'X-CSRF-TOKEN': token,
					},
					data: {
						key: key,
					},
				})
			.then(
				(response) => {
					dispatch('updateAjaxDialog', [false, false]);
					dispatch('getAxiosInstanceSettings')
				},
				(err) => {
					console.log('<constructor>[ERROR] Instance Settings is not Set')
					dispatch('updateAjaxDialogError', [err, 'Error', 'Instance Settings is not set']);
				}
			);

	}
};

const mutations = {
  //Настройки
  SET_ENGINE_SETTING(state, { field, value }) {
    Vue.set(state.engine_settings, field, value);
  },

  SET_ENGINES(state, engines) {
    Vue.set(state, 'engines', engines);
  },

  SET_BOT_SETTING(state, { field, value }) {
    Vue.set(state.bot_settings, field, value);
  },

  SET_BOT_SETTING_JSON_DATA(state, { field, value }) {
    Vue.set(state.bot_store.botSettings.json_data, field, value);
  },

  SET_BOTS(state, bots) {
    Vue.set(state, 'bots', bots);
  },

  SET_BOTS_GROUPS(state, bots) {
    Vue.set(state, 'botsGroups', bots);
  },

  SET_LANGUAGES(state, arr) {
    Vue.set(state, 'languages', arr);
  },
  //Настройки

  //Меню
  ADD_MENU_ITEM(state, item) {
    state.menu_items.push(item);
  },

  ADD_MODULE(state, { module_name, module_obj }) {
    Vue.set(state.modules, module_name, module_obj);
  },

  SET_MODULE(state, module) {
    if (module && state.modules[module]) {
      state.current_module = module;
    }
  },

  SET_TAB_TYPE(state, tab_type) {
    state.tab_type = tab_type;
  },
  //Меню

  SET_TIMEZONE(state, payload) {
    state.timezone = payload;
  },

	SET_INSTANCE_SETTINGS(state, payload) {
		state.instance_settings = payload;
	},
};

const getters = {
  user: (state) => {
    return state.user;
  },
  //Настройки
  engine_settings: (state) => {
    return state.engine_settings;
  },
  engines: (state) => state.engines,
  bot_settings: (state) => {
    return state.bot_settings;
  },
  bots: (state) => {
    return state.bots;
  },

  botsGroups: (state) => {
    return state.botsGroups;
  },

  languages: (state) => {
    return state.languages;
  },
  languages_obj: (state) => {
    let lang_obj = {};
    for (let lang of state.languages) {
      lang_obj[lang.code] = lang;
    }
    return lang_obj;
  },

  active_bot_title: (state) => {
		const botId = parseInt(router.currentRoute?.params?.id || state.bot_settings.active_id);
    let title = '';

    if (state.engine_settings.active_id && botId) {
      //Получение имени бота
      for (let bot of state.bots) {
        if (bot.id && bot.id === botId && bot.name) {
          title += bot.name;
        }
      }
    } else {
      title = 'Не выбран активный бот';
    }

    return title;
  },

  /**
   * getter for checkCurrentBot method and front rendering
   * @method getter all_bot_data_init
   * @return {{ready: boolean, message: string}}
   */
  all_bot_data_init: (state) => {
    let res = {
      ready: false,
      message: '',
    };

    if (
      !(
        state.engine_settings.active_id &&
        state.engine_settings.active_connection_status
      )
    ) {
      res.message = 'Engine settings error';
      return res;
    }

    if (
      !(
        state.bot_store.botSettings.id  && state.bot_store.defaultLanguageCode
      )
    ) {
      res.message = 'Bot settings error';
      return res;
    }

    res.ready = true;
    return res;
  },
  //Настройки

  //Menu
  menu_items: (state) => {
    return state.menu_items;
  },

  current_module: (state) => {
    return state.current_module;
  },

  current_module_obj: (state) => {
    if (state.current_module && state.modules[state.current_module]) {
      return state.modules[state.current_module];
    } else {
      return state.modules['dialog'];
    }
  },

  current_tab_type: (state) => {
    return state.tab_type;
  },
  //Menu

  //Роуты модулей
  modules_routes_obj: (state) => {
    return state.modules_routes_obj;
  },
  //Роуты модулей

  get_timezone: (state) => state.timezone,

  get_instance_settings: (state) => state.instance_settings
};

export default {
  state,
  mutations,
  actions,
  getters,
};
